


































































import { AuthRequestInterface } from "@/models/auth/AuthRequest.interface";
import { minLength, required } from "vuelidate/lib/validators";
import BaseInput from "@/components/UI/BaseInput.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Login",
  components: { BaseInput },
  validations: {
    form: {
      login: {
        required,
        min: minLength(3)
      },
      password: {
        required,
        min: minLength(12)
      }
    }
  },
  data() {
    return {
      form: {
        login: "",
        password: ""
      } as AuthRequestInterface,
      errorMessage: "",
      isLoading: false,
      showPass: false
    };
  },
  created() {
    if (this.$store.getters.updateTimer) return;

    clearTimeout(this.$store.getters.updateTimer);
  },
  methods: {
    login: async function(): Promise<void> {
      this.errorMessage = "";
      this.isLoading = true;
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      try {
        await this.$store.dispatch("login", {
          email: this.form.login.trim(),
          password: this.form.password.trim()
        });
      } catch (e) {
        if (e.message) {
          this.errorMessage = e.message;
          return;
        }

        this.errorMessage = "Произошла ошибка, обратитесь в службу поддержки";
      }

      await this.$router.push(
        this.form.login === "support@yandex.ru" ? "/register" : "/order-ftl"
      );
    }
  }
});
